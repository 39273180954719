<template>
  <div class="caseLayout details">
    <div class="case-conent-detal Loading-backg" v-if="dlog1 !=null">
      <div class="kong-loshd">
        <img src="../../assets/images/kong.png">
        <p style="font-size: 22px;color: #1A86FF">请等候！数据在努力加载中...</p>
      </div>
    </div>
    <div class="case-conent-detal Loading-backg" v-else>
      <!--第一块-->
      <div class="details-A">
        <div class="area-lf" :style="{ 'width': !showQuestion ? '100%': '' }">
          <!--左侧内容-->
          <div class="col_l_main">
            <!--轮播图内容-->
            <div class="mod-1">
              <div class="Hd">
                <div class="lf">
                  <img src="../../assets/images/Case-01.png" alt=""> 学历案查看
                  <el-button @click="backA()" icon="el-icon-back" type="primary" class="Btn-2" size="mini" round>
                    返回
                  </el-button>
                </div>
                <div class="bc">
                  <div class="dtY">
                    <el-select class="Btn-1"  @change="timeChang($event)"  v-model="query.id">
                      <el-option
                              v-for="item in timeData"
                              :key="item.id"
                              :label="item.id"
                              :value="item.id"
                      ></el-option>
                    </el-select>
                    秒/祯
                    <el-button class="Btn-2" @click="alwaysA()" style="border: 1px solid #AAD7F9;background-color: #AAD7F9;color: #2c3e50" size="mini" round>
                      <span v-if="alwaysName=='播放'">{{alwaysName}} <i class="el-icon-caret-right"></i></span>
                      <span v-else>{{alwaysName}} <i class="el-icon-video-pause"></i></span>
                    </el-button>
                  </div>
                  <div class="dtYPag">
                    第{{ indexPic + 1 }}张 / 共{{ total }}页
                  </div>
                </div>
                <div class="rg">

                  <el-button :disabled="disabled" class="k" size="mini" round>
                    打开Class客户端，查看学历案
                  </el-button>

                </div>
              </div>
              <div class="focus_PiC">
                <div class="scrollList">
                  <div class="Pic-left">
                    <div class="scrollbar infinite-list-wrapper">
                      <el-scrollbar ref="scroll">
                        <div v-for="(item, index) in picList" :key="index" :class="item.uid == indexPic ? 'pic-item active' : 'pic-item'" @click="clickItem(item, index)">
                          <img :src="'data:image/png;base64,'+item.base64" style="width:100%;">
                        </div>
                      </el-scrollbar>
                    </div>
                  </div>
                  <div class="Pic-right" v-if="alwaysName=='播放'">
                    <el-carousel :autoplay="false" arrow="always" indicator-position="none" :initial-index="indexPic" ref="carousel"  @change="changeCarousel">
                      <el-carousel-item v-for="(item, index) in picList" :key="index" >
                        <img  @click="ingObj(item)" :src="'data:image/png;base64,'+item.base64">
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <div class="Pic-right" v-else>
                    <el-carousel :interval="interval" :autoplay="true" arrow="always" indicator-position="none" :initial-index="indexPic" ref="carousel"  @change="changeCarousel">
                      <el-carousel-item v-for="(item, index) in picList" :key="index" >
                        <img  @click="ingObj(item)" :src="'data:image/png;base64,'+item.base64">
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
                <!--                <div class="pag-box" >-->
                <!--                  <div class="total-box">-->
                <!--                    <span class="total_pag">共<em> {{ total }} </em>条</span>-->
                <!--                  </div>-->
                <!--                  <el-pagination-->
                <!--                          :current-page="currentPage"-->
                <!--                          background-->
                <!--                          :page-size="pageSize"-->
                <!--                          layout="prev, pager, next"-->
                <!--                          :total="total"-->
                <!--                          @size-change="handleSizeChange"-->
                <!--                          @current-change="handleCurrentChange"-->
                <!--                  >-->
                <!--                    <span class="pag_sise">每页显示</span>-->
                <!--                  </el-pagination>-->
                <!--                </div>-->
              </div>
            </div>
            <!--答疑与一课一练内容-->
            <div class="mod-2">
              <div class="Hd">
                <li @click="CaseCurObjA()" :class="{activeA:CaseCurA==0}"><img src="../../assets/images/Case-06.png" alt=""> 学历案交互答疑</li>
                <li @click="CaseCurObjB()" :class="{activeA:CaseCurA==1}"><img src="../../assets/images/Case-04.png" alt=""> 一课一练</li>
              </div>
              <div class="mod-2-content">
                <div class="mod-2-content-itme" v-show="CaseCurA==0">
                  <div class="answering">
                    <div class="top">
                      <el-button class="bTn" :disabled="disabled" type="warning" size="mini" round @click="IwantQuestionObj()">
                        我要提问
                      </el-button>
                    </div>
                    <div class="answeringQuestion">
                      <div class="answering-lf">
                        <!--问题(或主题)列表-->
                        <div class="prolist" v-for="(item,index) in TopicListData" :key="index" @click="changeTopicList(index,item),cur=index" :class="{activeAnswering:cur==index}">
                          <div class="pl01">
                            <div class="pA">{{item.topic}}</div>
                            <div class="pB">
                              <el-button v-if="item.isReplay==1" size="mini" round class="ansBtn0">
                                专家已回答
                              </el-button>

                            </div>
                          </div>
                          <div class="pl02">
                            <div class="pA spaceD" :title="item.nickName">{{item.nickName}}</div>
                            <div class="pB">{{item.createTime}}</div>
                            <div class="pC">
                              有{{item.counts}}个讨论
                              <el-button :disabled="disabled" type="primary" plain @click="hf(item)" size="mini" round class="ansBtnA">回复</el-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="answering-rg">
                        <!--聊天列表-->
                        <div class="lt-inf">
                          <div class="lt-twwt" v-if="TcReplyLData.length > 0 ">
                            <div class="Tip">提问</div>

                            <div class="TntToplist">

                              <div class="portrait">
                                <img v-if="TopicReplyListChange.profilePhotoPath=='1'" src="../../assets/images/m1.png" alt="">
                                <img v-if="TopicReplyListChange.profilePhotoPath=='2'" src="../../assets/images/m2.png" alt="">
                                <img v-if="TopicReplyListChange.profilePhotoPath=='3'" src="../../assets/images/m3.png" alt="">
                                <img v-if="TopicReplyListChange.profilePhotoPath=='4'" src="../../assets/images/m4.png" alt="">
                                <img v-if="TopicReplyListChange.profilePhotoPath=='5'" src="../../assets/images/m5.png" alt="">
                                <p>{{TopicReplyListChange.nickName}}</p>
                              </div>
                              <div class="intR-content">
                                <div class="tR01">
                                  <div class="lT-A1">{{TopicReplyListChange.topic}}</div>
                                  <div class="lT-A2">{{TopicReplyListChange.createTime}}</div>
                                </div>
                                <div class="tR02">
                                  <div class="lT-content">
                                    <!--                                      <viewer :images="photos" @dblclick="showImg()" ">-->
                                    <span v-html="TopicReplyListChange.content" ></span>
                                    <!--                                      </viewer>-->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="lt-list" v-for="(int,idx) in TcReplyLData" :key="idx"  >
                            <div class="intlist" :class="idx==index?'TcReply':''" @click="changeTopicListB(idx,int)">

                              <div class="portrait">
                                <img v-if="int.photo=='999'" src="../../assets/images/intellect.png" alt="">
                                <img v-if="int.photo=='1'" src="../../assets/images/m1.png" alt="">
                                <img v-if="int.photo=='2'" src="../../assets/images/m2.png" alt="">
                                <img v-if="int.photo=='3'" src="../../assets/images/m3.png" alt="">
                                <img v-if="int.photo=='4'" src="../../assets/images/m4.png" alt="">
                                <img v-if="int.photo=='5'" src="../../assets/images/m5.png" alt="">
                                <img v-else-if="int.photo==''" src="../../assets/images/item-book-bg2.png" alt="">
                              </div>
                              <div class="intR">
                                <div class="tR01">
                                  <div class="lf spaceD" :title="int.nickName">
                                    {{int.nickName}}
                                    <!--                                        <span v-if="int.photo!='999'"><el-button class="ansBtnB" @click="changeLisDL(int)" :disabled="disabled"  type="primary" plain size="mini">回复</el-button>-->
                                    <!--</span>-->
                                  </div>
                                  <div class="rg">{{int.createTime}}</div>
                                </div>
                                <div class="tR02">
                                  <!--                                      <viewer :images="photo">-->
                                  <span v-html="int.content"></span>
                                  <!--                                    <img v-for="(item,index) in photos" :src="item.src" :key="index" alt="llll" style="background-color: white">-->
                                  <!--                                      </viewer>-->
                                </div>
                                <div class="tR03" v-if="int.photo!='999'">
                                  <div style="margin-right: 20px;color: #06A8C9"><img src="../../assets/images/Case-07.png" alt="">{{int.counts}}</div>
                                  <div v-if="int.isLike==0" style="color:#FA6E86" ><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>
                                  <div v-else style="color:#FA6E86"><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>

                                </div>
                                <div class="tR03" v-else>
                                  <div v-if="int.isLike==0" style="color:#FA6E86" ><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>
                                  <div v-else style="color:#FA6E86" ><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>

                                </div>
                              </div>
                            </div>
                            <div v-if="int.list.length>0">
                              <div class="Lintlist"  v-for="(Lin,index) in int.list" :key="index">
                                <div class="portrait">
                                  <img v-if="Lin.photo=='1'" src="../../assets/images/m1.png" alt="">
                                  <img v-if="Lin.photo=='2'" src="../../assets/images/m2.png" alt="">
                                  <img v-if="Lin.photo=='3'" src="../../assets/images/m3.png" alt="">
                                  <img v-if="Lin.photo=='4'" src="../../assets/images/m4.png" alt="">
                                  <img v-if="Lin.photo=='5'" src="../../assets/images/m5.png" alt="">
                                </div>
                                <div class="intR">
                                  <div class="tR01">
                                    <div class="lf spaceD" :title="Lin.nickName">{{Lin.nickName}}</div>
                                    <div class="rg">{{Lin.createTime}}</div>
                                  </div>
                                  <div class="tR02">
                                    <viewer :images="photo">
                                      <span v-html="Lin.content"></span>
                                      <!--                                      <img v-for="(item,index) in photos" :src="item.src" :key="index" alt="llll" style="background-color: white">-->

                                    </viewer>
                                  </div>
                                  <div class="tR03">
                                    <div v-if="Lin.isLike==0" style="color:#FA6E86" ><img src="../../assets/images/Case-08.png" alt="">{{Lin.likes}}</div>
                                    <div v-else style="color:#FA6E86" ><img src="../../assets/images/Case-08.png" alt="">{{Lin.likes}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="mod-2-content-itme" v-show="CaseCurA==1">
                  <div class="exercisesModular">
                    <div class="top">
                      <div class="lf">
                        <div class="paperTypes" v-if="this.paperTypes.length>0">
                          <li  :class="{activeB:CaseCurB==0}">A卷</li>
<!--                          <li @click="paperTypesB()" :class="{activeB:CaseCurB==1}">B卷</li>-->
<!--                          <li @click="paperTypesC()" :class="{activeB:CaseCurB==2}">C卷</li>-->
                        </div>
<!--                        <div class="paperTypes" v-if="this.paperTypes.length==2">-->
<!--                          <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>-->
<!--                          <li @click="paperTypesB()" :class="{activeB:CaseCurB==1}">B卷</li>-->
<!--                        </div>-->
<!--                        <div class="paperTypes" v-if="this.paperTypes.length==1">-->
<!--                          <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>-->
<!--                        </div>-->
                      </div>
                      <div class="rg">
                        <el-button v-if="this.status==1" type="warning" size="small" @click="commitAnaswer()" round>确定提交</el-button>
                        <el-button v-if="this.status==2" type="text" style="color:#299B4C">练习题已提交！</el-button>
                        <el-button v-if="this.status==3" type="text" style="color:#299B4C">练习题已批改！</el-button>
                      </div>
                    </div>
                    <div class="topicOr-card">
                      <div class="card-cod">
                        <div class="tCart" >
                          <div class="st">
                            <cace-home-work :homeworks="questionData"></cace-home-work>
                          </div>
                          <div class="dt">
                            <div class="AnswerSheet">
                              <div class="Acontent" v-for="(item,i) in formData" :key="i">
                                <div class="Aname">{{item.name}}</div>
                                <div class="Acn topic" v-for="(items,index) in item.list" :key="index">
                                  <!--选择题-->
                                  <div v-if="items.typeName=='选择题'">
                                    <div class="Alist">
                                      <div class="A1">{{items.questionNo}}</div>
                                      <div class="A2">
                                        <div class="Aoption">

                                          <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                                               :class="{active:answers[items.id] ==Aoption}"
                                          >
                                            {{Aoption}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <!--duo xuan 题-->
                                  <!--                       <div v-if="items.typeName=='选择题'">-->
                                  <!--                         <div class="Alist">-->
                                  <!--                           <div class="A1">{{items.questionNo}}</div>-->
                                  <!--                           <div class="A2">-->
                                  <!--                             <div class="Aoption">-->

                                  <!--                               <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"-->
                                  <!--                                    @click="changeMoreList(Aoption,Aop, items)"-->
                                  <!--                                    :class="{active:answers[items.id] && answers[items.id].includes(Aoption)}"-->
                                  <!--                               >-->
                                  <!--                                 {{Aoption}}-->
                                  <!--                               </div>-->
                                  <!--                             </div>-->
                                  <!--                           </div>-->
                                  <!--                         </div>-->
                                  <!--                       </div>-->



                                  <!--非选择题-->
                                  <div v-if="items.typeName!='选择题'">
                                    <div class="Alist">
                                      <div class="A1" @click="AnswerPad()">{{items.questionNo}}</div>
                                      <div class="A2">
                                        <div class="Foption">
                                          <!--                               <div class="A-tem" v-for="(Atem,i) in items.empty" :key="i"></div>-->
                                          <!--<vue-ueditor-wrap :config="editorConfig" v-model="answers[items.id]"></vue-ueditor-wrap>-->
                                          <!--预览-->
                                          <div class="ueditorWrap" v-if="disabled==1">
                                            <div class="EtorToolbarboxA">
                                              <div class="Etor-01"></div>
                                              <div class="Etor-02"></div>
                                            </div>
                                            <div class="EtorToolbarboxB"></div>
                                          </div>
                                          <!--非语篇-->
                                          <com-ueditor-wrap v-else :questionId="items.id" :parentId="items.parentId" :question="questionData"  :content.sync="answers[items.id]" :params="{id: items.id}" @change="changeUeditor"></com-ueditor-wrap>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--展开收起-->
          <div class="Btn">
            <div class="BtnA" @click="showQuestion = !showQuestion" v-show="showQuestion"></div>
            <div class="BtnB" @click="showQuestion = !showQuestion" v-show="!showQuestion"></div>
          </div>
        </div>
        <div class="area-rg" v-show="showQuestion">
          <div class="bick-r-01" @click="back()">
            <img src="../../assets/images/lesson.png" alt="">
          </div>
          <div class="bick-r-02">
            <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    label-width=""
            >
              <div class="blackName">
                <img src="../../assets/images/Case-05.png" alt=""> 学历案笔记
              </div>
              <div class="note">
                <div class="notecase">
                  <div class="cName">
                    <div class="Aam1">线索</div>
                    <div class="Aam2">
                      <el-tooltip effect="dark" popper-class="testtooltip" placement="bottom">
                        <div slot="content">提炼归纳<br/>重要知识点<br/>帮助回忆的提示……</div>
                        <i class="el-icon-question"></i>
                      </el-tooltip>

                    </div>
                  </div>
                  <div class="cList">
                    <div class="list">
                      <div class="LA">

                      </div>
                      <div class="LB">
                        <div class="ueditorWrap2" v-if="disabled==1">
                          <div class="EtorToolbarboxA">
                            <div class="Etor-01"></div>
                            <div class="Etor-02"></div>
                          </div>
                          <div class="EtorToolbarboxB"></div>
                        </div>
                        <ComUeditorWrapNote1 v-else :note1="ruleForm.note1" :content="answers[ruleForm.note1]" :params="{id: ruleForm.note1}"></ComUeditorWrapNote1>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="notecase">
                  <div class="cName">
                    <div class="Aam1">笔记内容</div>
 <!--                   <div class="Aam2">
                      <el-tooltip effect="dark" popper-class="testtooltip" placement="bottom">
                        <div slot="content">在这里记录具体的重点内容<br/>使用简洁的文字、符号、缩写来记录<br/>自己的感悟……</div>
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>-->

                  </div>
                  <div class="cList">
                    <div class="list">
                      <div class="LA">
                      </div>
                      <div class="LB">
                        <div class="ueditorWrap2" v-if="disabled==1">
                          <div class="EtorToolbarboxA">
                            <div class="Etor-01"></div>
                            <div class="Etor-02"></div>
                          </div>
                          <div class="EtorToolbarboxB"></div>
                        </div>
<!--                        <ComUeditorWrapNote4 v-else :note4="ruleForm.note4" :content="answers[ruleForm.note4]" :params="{id: ruleForm.note4}"></ComUeditorWrapNote4>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="notecase">
                  <div class="cName">
                    <div class="Aam1">总结</div>
                    <div class="Aam2">
                      <el-tooltip effect="dark" popper-class="testtooltip" placement="bottom">
                        <div slot="content">对全部内容的思考与提炼<br/>记录下自己对课程的疑惑，以及后续的解答<br/>补充当时课程上没有记全面的内容<br/>写下与其他相关知识点的链接页码……</div>
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="cList">
                    <div class="list">
                      <div class="LA">
                      </div>
                      <div class="LB">
                        <div class="ueditorWrap2" v-if="disabled==1">
                          <div class="EtorToolbarboxA">
                            <div class="Etor-01"></div>
                            <div class="Etor-02"></div>
                          </div>
                          <div class="EtorToolbarboxB"></div>
                        </div>
                        <ComUeditorWrapNote7 v-else :note7="ruleForm.note7" :content="answers[ruleForm.note7]" :params="{id: ruleForm.note7}"></ComUeditorWrapNote7>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="noteBTn" >
                <el-button :disabled="disabled" type="primary" icon="el-icon-download" size="mini" round
                           class="bTn">
                  导出成word
                </el-button>
                <el-button :disabled="disabled" type="danger"  icon="el-icon-document-checked" size="mini" round
                           class="bTn">保存
                </el-button>
              </div>
            </el-form>
          </div>


        </div>
      </div>


    </div>

    <!-- 我要提问框-->
    <el-dialog :visible.sync="IwantQuestion" :close-on-click-modal="false" :modal-append-to-body='false'  title="我要提问" width="800px" left>
      <div class="dialogHd"
           style="height: 170px">
        <el-input
                v-model="QuestionFrom.topic"
                maxlength="500"
                :rows="8"
                placeholder="请输入"
                show-word-limit
                type="textarea"
        />
      </div>
      <template #footer>
        <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">
          <el-button @click="addTopic()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 第一级回复框-->
    <el-dialog :visible.sync="AnswerDialogA" :close-on-click-modal="false" :modal-append-to-body='false'  title="回复讨论" width="800px" left>
      <el-form
              ref="AnswerForm"
              :model="AnswerForm"
              status-icon
              :rules="rules"
              label-width="100px"
      >
        <div class="AnswerForm">

          <div class="list">
            <el-form-item label="发言：" prop="">
              <span>{{AnswerDialogAData.topic}}</span>
            </el-form-item>
          </div>
          <div class="list">
            <el-form-item label="昵称：" prop="">
              <span>{{AnswerDialogAData.nickName}}</span>
            </el-form-item>
          </div>
          <div class="list">
            <el-form-item label="回复：" prop="">
              <el-input
                      v-model="AnswerForm.replyMsg"
                      maxlength="300"
                      :rows="8"
                      placeholder="请输入"
                      show-word-limit
                      type="textarea"
              />
            </el-form-item>
          </div>
        </div>

      </el-form>
      <template #footer>
        <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">
          <el-button @click="replyTopic()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 第二级回复框-->
    <el-dialog :visible.sync="AnswerDialogB" :close-on-click-modal="false" :modal-append-to-body='false'  title="回复讨论" width="800px" left>
      <el-form
              ref="AnswerFormB"
              :model="AnswerFormB"
              status-icon
              :rules="rules"
              label-width="100px"
      >
        <div class="AnswerForm">


          <div class="list">
            <el-form-item label="发言：" prop="">
              <span>{{replyTopicBData.topic}}</span>
            </el-form-item>
          </div>
          <div class="list">
            <el-form-item label="昵称：" prop="">
              <span>{{replyTopicBData.nickName}}</span>
            </el-form-item>
          </div>
          <div class="list">
            <el-form-item label="回复：" prop="">
              <el-input
                      v-model="AnswerFormB.replyMsg"
                      maxlength="300"
                      :rows="8"
                      placeholder="请输入"
                      show-word-limit
                      type="textarea"
              />
            </el-form-item>
          </div>
        </div>

      </el-form>
      <template #footer>
        <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">
          <el-button @click="chaListBDL()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 我要提问框-->
    <el-dialog :visible.sync="isshowImg" :close-on-click-modal="false" :modal-append-to-body='false'  title="图片预览" width="90%" left>
      <div class="dialogHd">
        <img :src="'data:image/png;base64,'+imGurl" style="width: 80%" />
      </div>
    </el-dialog>
    <!-- 未答题提示框-->
    <el-dialog :visible.sync="AnswerDialogF" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="800px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>您未答题</h2>
          <p>不能做无效操作！请答题</p>
        </div>
        <div class="Mtn">
          <el-button @click="AnswerDialogObJ()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 您未答完题，确认提示框-->
    <el-dialog :visible.sync="AnswerDialogG" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>还有试题未做</h2>
          <p>是否提交？或继续答题</p>
          <div class="R">提交后，系统将开始进行测评</div>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswer()" type="primary">仅保存</el-button>
          <el-button @click="SubmitHomework()" plain round  type="warning">直接提交</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 您已做完题，确认提示框-->
    <el-dialog :visible.sync="AnswerDialogH" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交试卷答案" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <p>是否提交本次试卷答案？</p>
          <div class="R">提交后，系统将开始进行测评</div>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswer()" type="primary">仅保存</el-button>
          <el-button @click="SubmitHomework()" plain round  type="warning">直接提交</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- A卷答案保存，确认提示框-->
    <el-dialog :visible.sync="saveDialogA" :close-on-click-modal="false" :modal-append-to-body='false'  title="保存试题答案" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>A卷已保存成功</h2>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="CloesaveAnaswerA()" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- B卷答案保存，确认提示框-->
    <el-dialog :visible.sync="saveDialogB" :close-on-click-modal="false" :modal-append-to-body='false'  title="保存试题答案" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>B卷已保存成功</h2>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="CloesaveAnaswerB()" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- C卷答案保存，确认提示框-->
    <el-dialog :visible.sync="saveDialogC" :close-on-click-modal="false" :modal-append-to-body='false'  title="保存试题答案" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>C卷已保存成功</h2>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="CloesaveAnaswerC()" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- C卷提示，确认提示框-->
    <el-dialog :visible.sync="SnswerTC" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>恭喜，试卷已经全部做完！</h2>
          <p>提交后，系统讲进行测评</p>
        </div>
        <div class="Mtn">
          <el-button @click="SnswerTBtn2()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">返回</el-button>
          <el-button @click="toEvaluation()" plain round  type="warning">查看测评</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="AnswerDialogJ" :close-on-click-modal="false" :modal-append-to-body='false'  title="提示" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>还有试题未做</h2>
          <p>是否要返回上一级？</p>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswerA()" type="primary">仅保存</el-button>
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="back()" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import { mapState } from 'vuex'
  import caceHomeWork from "../../components/caceHomeWorkA";
  import {studyXlaObj,TopicListObj,TopicReplyListObj,queryXlaImgObj} from '@/api/tourist'   //路径
  export default {
    name: '',
    inject: ['reload'],
    data() {
      return {
        dlog1:null,
        loading: false,
        status:null,//1: 未提交 ： 2 ： 已提交 ： 3 ：已批改
        CaseCurA:false,
        CaseCurB:false,
        bottomShow: true,
        SnswerTA:false,
        SnswerTB:false,
        SnswerTC:false,
        saveDialogA:false,
        saveDialogB:false,
        saveDialogC:false,
        qType:null,
        disabled:0,
        always:1,//1为播放，2 为暂停
        alwaysName:"播放",//1为播放，2 为暂停
        interval:'300',//图片播放时长
        AnswerDialogA:false,//第一级回复
        AnswerDialogB:false,//第二级回复
        AnswerDialogF:false,//未答题提示框
        AnswerDialogG:false,//您未答完题，确认提交
        AnswerDialogH:false,//您已做完题，确认提交
        AnswerDialogAData:{},
        AnswerDialogJ:false,//点击返回未答完题，确认保存
        replyTopicBData:{},//第二级
        AnswerPadDialog:false,//答题写字板弹框
        value: null,
        NodesId:null,
        likeTopicData:{},
        likeT:0,

        currentPage:null,
        pageSize:null,
        total:null,
        picImgSrc: {},
        portraitList: [
          {id:1, url: require('../../assets/images/m1.png')},
          {id:2, url: require('../../assets/images/m2.png')},
          {id:3, url: require('../../assets/images/m3.png')},
          {id:4, url: require('../../assets/images/m4.png')},
          {id:5, url: require('../../assets/images/m5.png')},
        ],
        timeData:[
          {
            id:1,
            name:'1000'
          },
          {
            id:2,
            name:'2000'
          },
          {
            id:3,
            name:'3000'
          },
          {
            id:4,
            name:'4000'
          },
          {
            id:5,
            name:'5000'
          },
          {
            id:6,
            name:'6000'
          },
          {
            id:7,
            name:'7000'
          },
          {
            id:8,
            name:'8000'
          },

        ],

        options:{
          isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
          lastWriteWidth: 2,  //下笔的宽度 [Number] 可选
          lineCap: 'round',   //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
          lineJoin: 'round',  //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
          canvasWidth: 1200, //canvas宽高 [Number] 可选
          canvasHeight: 600,  //高度  [Number] 可选
          isShowBorder: true, //是否显示边框 [可选]   当签名模式处于false的时候此选项才生效
          bgColor: '#E8F7FE', //背景色 [String] 可选
          borderWidth: 1, // 网格线宽度  [Number] 可选
          borderColor: "#AFE6FF", //网格颜色  [String] 可选
          writeWidth: 2, //基础轨迹宽度  [Number] 可选
          maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
          minWriteWidth: 2, // 写字模式最小线宽  [Number] 可选
          writeColor: '#101010', // 轨迹颜色  [String] 可选
          imgType:'png'   //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
        },


        ruleForm:{
          note1: '',
          note2: '',
          note3: '',
          note4: '',
          note5: '',
          note6: '',
          note7: '',
          note8: '',
          note9: '',
          note10: '',
        },
        AnswerForm:{
          replyMsg: '',
        },
        AnswerFormB:{
          replyMsg: '',
        },
        rules: {

          nickName: [
            {
              required: true,
              message: '请输入昵称',
              trigger: 'blur',
            },
          ],
        },

        le2:null,
        cur: 0, //默认选中第一个tab
        repA:0,
        index:0,
        isLike3:null,
        topicId2:null,
        detailQus:1,
        NoBuy:null,
        editorConfig:{
          // 你的UEditor资源存放的路径,相对于打包后的index.html
          //UEDITOR_HOME_URL: "/UEditor/",
          UEDITOR_HOME_URL: '/ueditor/',
          serverUrl: process.env.VUE_APP_BASE_URL + "/study/baiduEditor",
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          // 初始容器高度
          initialFrameHeight: 300,
          // 初始容器宽度
          initialFrameWidth: "100%",
          // 关闭自动保存
          enableAutoSave: false,
          // 自定义工具栏，需要额外选项可以参考ueditor.config.js
        },
        qus:null,
        setNote1:'',
        setNote4:'',
        setNote7:'',
        type: null,
        content: '',
        answers: {}, // 选择题答题内容
        isactive: false,
        imGurl:'',
        paperTypes:[],
        questionData:[],// 试题
        formData:[],// 答题卡
        current:null,
        currentNum:'',
        cid:null,
        isshowImg:false,
        queryResData:{},
        IwantQuestion:false,//我要提问-弹框
        TopicListData:[],//论坛主题列表
        TcReplyLData:[],//论坛主题回复列表
        level:null,
        query:{
          id:null
        },
        prolisFrom:{
          replyMsg:''
        },
        QuestionFrom:{
          topic:''
        },
        showQuestion: true,//展开收起
        indexPic: 0,
        heightpx: '320px',

        picList:[

        ],
        picListCarousel:[],


        currentIndex: 0, // 选项卡当前的索引
        saveTopicId:null,
        noMore: false,//没有更多了

      }
    },
    created() {

      this.EducationCase = this.$route.query.EducationCase;
      this.clickFlag=this.$route.query.clickFlag;
      this.bookId = this.$route.query.bookId;
      this.folderId = this.$route.query.folderId;
      this.disabled = this.$route.query.disabled;
      this.type = this.$route.query.type;

      this.id = this.$route.query.id;
      this.NoBuy= this.$route.query.NoBuy;
      this.stu = this.$route.query.stu
      this.token= localStorage.getItem('Authorization')

      this.queryRes()
      this.TopicList()
      this.timeObj()
      this.queryXlaImg()




    },
    mounted() {
      this.questionType = 1
      this.type=1
    },
    components: {
      caceHomeWork,
    },

    methods: {

      CaseCurObjA() {
        this.type=1
        this.CaseCurA=0
      },
      CaseCurObjB() {
        this.CaseCurA=1
      },
      paperTypesA() {
        this.type=1
        this.answers= {}
        this.queryRes()
        this.CaseCurB=0
      },
      paperTypesB() {
        this.type=2
        this.answers= {}
        this.queryRes()
        this.CaseCurB=1
      },
      paperTypesC() {
        this.type=3
        this.answers= {}
        this.queryRes()
        this.CaseCurB=2
      },

      //返回
      back() {
        this.$router.go(-1);
      },
      opENClass () {
        location.href = 'LearningSystem://class'
      },


      alwaysA() {
        this.always=1
        this.bottomShow = !this.bottomShow;
        if(this.bottomShow){
          this.alwaysName = '播放'
        }else{
          this.alwaysName = '暂停'
        }
      },
      alwaysB() {
        this.always=2
      },
      changeUeditor (val, params) {
        console.log(params.id,'00000000')
        this.$set(this.answers, params.id, val)
        console.log(this.answers,'00')
      },
      /**
       * 清除画板
       */
      canvasClear(){
        this.$refs.SignCanvas.canvasClear();
      },

      /**
       * 保存图片
       */
      saveAsImg(){
        const img = this.$refs.SignCanvas.saveAsImg();
        alert(`image 的base64：${img}`);
      },

      /**
       * 下载图片
       */
      downloadSignImg(){
        this.$refs.SignCanvas.downloadSignImg();
      },
      AnswerPad() {
        this.AnswerPadDialog = true
      },

      timeObj() {
        this.query.id = this.timeData[3].id
        this.interval = this.timeData[3].name
      },






      changeCarousel (count) {
        this.$refs['scroll'].wrap.scrollTop = count == 0 ? 0 : 110 * count - 50 //想滚到哪个高度，就写多少
        this.indexPic = count
      },
      reset () {
        this.answers = []
        this.refresh()
      },
      getEditorValue (value, q) {
        console.log(value, q, 'value, q')
      },


      // exportStudyNodes() {
      //   let obj = {
      //     resourceId: this.id,
      //   }
      //   exportStudyNodesObj(obj).then((res) => { // eslint-disable-line no-unused-vars
      //     console.log(res,'900000')
      //     let blob = new Blob([res.data],{ type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'});
      //     let objectUrl = URL.createObjectURL(blob);
      //     window.location.href = objectUrl;
      //   })
      // },
      //
      queryXlaImg() {
        this.dlog1= 1
        let obj = {
          id: this.id,
        }
        let data = {
          pageCurrent: 1,//初始页
          pageSize: 10,//每页的数据
        }
        queryXlaImgObj(obj,data).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.dlog1= null
            if(this.NoBuy==1) {
              this.picList = res.data.records
              let count = -1
              for(let i of this.picList){
                count+=1
                i.uid= count
              }
              this.pageSize = res.data.size;
              this.currentPage = res.data.current;
              this.total = 10
              this.$nextTick(() => {
                this.setActiveItem(this.indexPic)
              })
            } else {
              this.picList = res.data.records
              let count = -1
              for(let i of this.picList){
                count+=1
                i.uid= count
              }
              this.pageSize = res.data.size;
              this.currentPage = res.data.current;
              this.total = res.data.total
              this.$nextTick(() => {
                this.setActiveItem(this.indexPic)
              })
            }
          }


        })
      },
      handleSizeChange(val) {
        this.pagesize = val;
        this.indexPic=0
        this.queryXlaImg();
      },
      handleCurrentChange(val) {
        this.indexPic=0
        this.currentPage = val;
        // this.setContextData("currentPage8", this.currentPage);
        // 刷新页面内容
        this.queryXlaImg();
      },
      //学历案详情和试题
      queryRes() {
        if(this.paperTypes.length>0) {
          let obj = {
            bookId: this.bookId,
            folderId: this.folderId,
            id: this.id,
            // type: this.type
            type: 1
          }
          studyXlaObj(obj).then((res) => { // eslint-disable-line no-unused-vars
            let count = -1
            for(let i of this.picList){
              count+=1
              i.uid= count
            }
            this.picList = res.data.imgs
            this.paperTypes = res.data.paperTypes
            this.picListCarousel = res.data.imgs
            this.paperId = res.data.qus[0].id
            this.questionId = res.data.qus[0].id
            this.type = res.data.qus[0].type
            this.questionData = res.data.qus[0].question
            this.$store.commit('changeType', res.data.paperTypes?.[0])
            this.formData = res.data.qus[0].answerSheet
            this.$nextTick(() => {
              this.setActiveItem(this.indexPic)
            })

          })
        }else {
          let obj = {
            bookId: this.bookId,
            folderId: this.folderId,
            id: this.id,
            type: 1
          }
          studyXlaObj(obj).then((res) => { // eslint-disable-line no-unused-vars
            let count = -1
            for(let i of this.picList){
              count+=1
              i.uid= count
            }
            //this.picList = res.data.imgs
            this.paperTypes = res.data.paperTypes
            this.picListCarousel = res.data.imgs
            this.paperId = res.data.qus[0].id
            this.questionId = res.data.qus[0].id
            this.type = res.data.qus[0].type
            this.questionData = res.data.qus[0].question
            this.$store.commit('changeType', res.data.paperTypes?.[0])
            this.formData = res.data.qus[0].answerSheet
            this.$nextTick(() => {
              this.setActiveItem(this.indexPic)
            })

          })
        }

      },



      //问题(或主题)列表
      TopicList() {
        var bookId = this.bookId
        var folderId = this.folderId
        var id = this.id
        let obj = {

        }
        TopicListObj(obj,bookId,folderId,id).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.TopicListData = res.data
            if (this.TopicListData.length > 0) {
              this.changeTopicList(0, this.TopicListData[0])
            }
          }
        })
      },
      //点击获取问题(或主题)列表
      changeTopicList(index,item) {
        this.le2=1
        sessionStorage.setItem('curChange',index)
        this.cur=sessionStorage.getItem('curChange')
        this.currentIndex = index;
        this.level = item.level
        sessionStorage.setItem('topicIdChange',item.id)
        sessionStorage.setItem('TopicReplyListChange',JSON.stringify(item))
        // 获取本地存储的左侧点击数据
        this.topicId = item.id
        //刷新-聊天列表-第一级
        this.TopicReplyList()
      },
      //聊天列表-第一级
      TopicReplyList() {
        if(sessionStorage.getItem('curChange')!=undefined &&sessionStorage.getItem('curChange')!='') {
          this.cur = sessionStorage.getItem('curChange')
        } else {
          this.cur =0
        }
        // 获取本地存储的左侧点击数据
        this.TopicReplyListChange = JSON.parse(window.sessionStorage.getItem('TopicReplyListChange'))
        if(this.TopicReplyListChange!=undefined &&this.TopicReplyListChange!='') {
          this.topicId = this.TopicReplyListChange.id
          this.TcReplyLData = this.TopicReplyListChange
        } else {
          this.topicId = this.TopicListData[0].id
          this.TopicReplyListChange=this.TopicListData[0]
          this.cur =0
          console.log(this.TopicListData[0].id,'非本地获取----')
        }
        this.TcReplyLData = [];//论坛主题回复列表
        let obj = {
          bookId:this.bookId,
          folderId:this.folderId,
          id:this.id,
          topicId:this.topicId,
        }
        TopicReplyListObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.TcReplyLData = res.data
            // this.level=''
            // this.topicId=''
            console.log(this.TcReplyLData,'论坛主题回复列表')
          }
        })


      },

      //回复
      hf(item) {
        this.AnswerDialogA=true
        this.AnswerDialogAData=item
        console.log(item.topic,'993994')
      },
      //点击选中背景颜色，拿到该行数据
      changeTopicListB(idx) {
        this.index=idx
      },
      // 点击打开回复弹框
      changeLisDL(int) {
        this.replyTopicBData= int
        this.AnswerDialogB=true
      },
      changeList(items,Aop, parent){
        this.currentNum = parent.questionNo
        this.current = Aop;//this指向app      :class="{active:Aop==current}
        this.cid = items.id;//this指向app
        this.$set(this.answers, parent.id, items)
        // this.answers[parent.id] = items
        console.log(this.answers, '选中的选项')

      },
      changeMoreList(items,Aop, parent){
        this.currentNum = parent.questionNo
        this.current = Aop;//this指向app      :class="{active:Aop==current}
        this.cid = items.id;//this指向app
        if (!this.answers[parent.id]) {
          this.$set(this.answers, parent.id, [])
        }
        let idx = this.answers[parent.id].findIndex(it => it === items)
        if (idx == -1) {
          this.answers[parent.id].push(items)
        } else {
          this.answers[parent.id].splice(idx, 1)
        }
        console.log(this.answers, '选中的选项')

      },
      // 时间
      timeChang(id){
        let obj = {};
        obj = this.timeData.find((item)=>{//model就是上面的数据源
          return item.id === id;//筛选出匹配数据
        });
        this.interval = obj.name
        this.query.id=obj.id
        console.log(this.interval,'this.interval')
        console.log(obj.name,'obj.name')
        console.log(obj.id,'obj.id')
      },
      // 清除时间
      timeClear() {
        sessionStorage.removeItem('coursewareYear');
        this.StuBooksObj()
      },
      // 关闭未答题框
      AnswerDialogObJ() {
        this.AnswerDialogF=false
      },
      // 未做完返回
      backA() {
        let questionIdList = Object.keys(this.answers)
        let values = Object.values(this.answers)
        let isNull = false //是否有未答得题目
        console.log(questionIdList.length ,this.quesSum,'shit')

        let answerList = questionIdList.map(item => {
          if (!this.answers[item] && values.some(it => it !== '')) {
            isNull = true
          }
          return {
            questionId: Number(item),
            answer: this.answers[item]
          }
        })
        if(values.every(it => it === '')) {
          // 您未答题提醒
          this.back()
        }else if(isNull){
          // 您未答完题，确认提交吗
          this.AnswerDialogJ=true
        }else {
          this.back()
          // let obj = {
          //   answer: this.answers,
          //   questionId:this.questionId
          // }
          //this.SubmitHomework()
        }







      },
      //提交作业
      commitAnaswer() {
        // this.paperTypes.forEach((item,index)=>{
        //   // if(item==1){
        //   //   console.log(item,'900')
        //   // }
        // })
        var paperId = this.paperId
        console.log(this.formData,'8888888888')
        console.log(this.answers,'shit')
        let questionIdList = Object.keys(this.answers)
        let values = Object.values(this.answers)
        let isNull = false //是否有未答得题目
        console.log(questionIdList.length ,this.quesSum,'shit')

        let answerList = questionIdList.map(item => {
          if (!this.answers[item] && values.some(it => it !== '')) {
            isNull = true
          }
          return {
            questionId: Number(item),
            answer: this.answers[item]
          }
        })
        console.log(values,'qqqq')
        // 未答题提示
        if(values.every(it => it === '')) {
          // 您未答题提醒
          this.AnswerDialogF=true
        }else if(isNull){
          // 您未答完题，确认提交吗
          this.AnswerDialogG=true
        }else {
          this.AnswerDialogH=true
          // let obj = {
          //   answer: this.answers,
          //   questionId:this.questionId
          // }
          //this.SubmitHomework()
        }
      },
      SnswerTBtn1() {
        this.$store.commit('changeType', this.questionType + 1)
        console.log(this.questionType,'88282')
        this.reload()
        this.reset()
        this.answers={}



      },
      SnswerTBtn2() {
        this.back()
        this.reload()
        this.reset()
      },
      SnswerTBtn3() {
        this.answers={}
        this.type=3
        this.back()
        this.reset()
      },

      // 关闭
      CloesaveAnaswerA() {
        this.AnswerDialogG=false  // 还有试题未做弹框关闭
        this.saveDialogA=false //A卷答案保存关闭
      },
      CloesaveAnaswerB() {
        this.AnswerDialogG=false  // 还有试题未做弹框关闭
        this.saveDialogB=false //B卷答案保存关闭
      },
      CloesaveAnaswerC() {
        this.AnswerDialogG=false  // 还有试题未做弹框关闭
        this.saveDialogC=false //C卷答案保存关闭
      },
      CloeSubmitHomework() {
        this.AnswerDialogG=false
      },
      ingObj(item) {
        console.log(item.id,'8888')
        this.imGurl = item.base64
        this.isshowImg = true
      },



      setActiveItem (i) {
        console.log(i,"ccccccccccccccc")
        if (this.$refs.carousel) {
          this.$refs.carousel.setActiveItem(i)
        }
      },

      clickItem (item, index) {
        // console.log(item.uid,"dddddddddddddddddd")
        this.indexPic = item.uid
        // let apicDom = document.getElementsByClassName('pic-item')[0]
        // console.log(apicDom.scrollHeight, this.$refs.scroll.$refs.wrap.scrollTop)
        // let domScrollTop = apicDom * index
        // if (domScrollTop > this.$refs.scroll.$refs.wrap.scrollHeight) {
        //   domScrollTop = this.$refs.scroll.$refs.wrap.scrollHeight
        // }
        // this.$refs.scroll.$refs.wrap.scrollTop = domScrollTop
        // console.log(this.$refs.scroll.$refs.wrap.scrollTop)

        this.setActiveItem(item.uid)
      },
      tabChange(val) {
        this.activeIndex = val;
        this.$router.push({   //新增一个参数
          query:{tabsid:val}
        });

      },
      //查询列表
      listObj() {
        this.listData = this.list
      },
      //我要提问-弹框
      IwantQuestionObj() {
        this.IwantQuestion = true
      },
      //去知识内容测评
      toEvaluation() {
        this.$router.push({
          path: "/mainb/evaluation",
          query: {
            EducationCase:this.EducationCase,
            stu:this.stu,
            clickFlag:2
          }
        });
      },
      //去单元实战演练
      toUnitTest() {
        this.$router.push({
          path: "/mainb/UnitTestQuestions",
          query: {
            EducationCase:this.EducationCase,
            bookId:this.bookId,
            folderId:this.folderId,
            id:this.id,
            disabled:this.disabled,
            stu:this.stu,
            clickFlag:0
          }
        });
      }
    },
    watch: {
      questionType: {
        handler: function() {
          this.queryRes()
        },
      },
      'TopicListObj' : {
        handler : function(newVal,oldVal){
          this.TopicLnewVal=newVal
          console.log(newVal,'变化后的值',)
        }
      },
      'TopicList' : {
        handler : function(newVal,oldVal){
          this.TopicListData=newVal
          console.log(newVal,'变化后的值',)
        }
      },
      // 详情试题监听
      'queryResData' : {
        handler : function(newVal){
          this.queryResData=newVal
        }
      },
      // 问题(或主题)列表监听
      'TopicListData' : {
        handler : function(newVal){
          this.TopicListData=newVal
        }
      },
      // 聊天列表-第一级监听
      'TcReplyLData' : {
        handler : function(newVal){
          this.TcReplyLData=newVal
        }
      },


    },
    computed:{
      ...mapState([
        'questionType',
        'StuByUserData',
      ]),
      disabledNoMore () {
        return this.loading || this.noMore
      }
    },


  }
</script>
<style lang="scss" scoped>
  .dialogHd {
    img{
      margin: auto;
    }
  }
  .TcReply {
    background-color: #B2E7FF;
  }
  .AnswerPadBox {
    width: 96%;
    height: 700px;
    margin: auto;
    .sign-canvas{
      display: block;
      margin: 0 auto;
      border: 1px dashed #AFE6FF;
    }
    .view-image{
      display: block;
      margin: 20px auto;
    }
    .sign-btns{
      width: 800px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      #clear,
      #clear1,
      #save {
        margin: 0 auto;
        display: inline-block;
        padding: 5px 10px;
        width: 150px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #eee;
        background: #e1e1e1;
        border-radius: 10px;
        text-align: center;
        margin: 20px auto;
        cursor: pointer;
      }
    }
  }
  .AnswerForm {
    width: 98%;
    margin: auto;
    .list {
      width: 100%;
      ::v-deep.el-form-item {
        margin-bottom: 0px;
      }
    }
  }
  // 作业提交提示
  .TipM {
    width: 96%;
    margin: auto;
    .Msg {
      width: 100%;
      text-align: center;
      h2 {
        color: #FF4D00;
      }
      p {
      }
      .R {
        color: #999;
        padding-top: 20px;
      }
    }
    .Mtn {
      width: 100%;
      margin-top: 20px;
      text-align: center;

    }
  }
  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }

  .ueditorWrap{
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    position: relative;
    overflow: visible;
    border-radius: 0px;
    .EtorToolbarboxA {
      width: 100%;
      height: 30px;
      position: relative;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom: 1px solid #d4d4d4;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
      .Etor-01 {
        width: 60px;
        height: 30px;
        float: left;
        justify-content: flex-start;
        background: url("../../assets/images/skPad.png") no-repeat 15px 2px;
        background-size:26px 26px;
      }
      .Etor-02 {
        width: 40px;
        height: 30px;
        justify-content: flex-end;
        float: right;
        /*background: url("../../assets/images/Case-11.png") no-repeat 15px 7px;*/
        /*background-size:16px 16px;*/
      }

    }
    .EtorToolbarboxB {
      height: 300px;
      padding: 10px;
      overflow-y: auto;
      box-sizing: border-box;
      .studentAnswer {
        width: 98%;
        margin: auto;
        //滚动条的宽度
        ::v-deep ::-webkit-scrollbar {
          width: 8px;
          height: 10px;
          cursor: pointer
        }

        //滚动条的滑块
        ::-webkit-scrollbar-thumb {
          background-color: rgba(0,0,0,0.2);
          border-radius: 12px;
          cursor: pointer
        }
        ::v-deep p {
          img{
            width: 100% !important;
          }
        }
      }




    }
  }
  .ueditorWrap2{
    width: 420px;
    border: 1px solid #FFDAA8;
    background-color: #FBF8D9;
    position: relative;
    overflow: visible;
    border-radius: 0px;
    .EtorToolbarboxA {
      width: 100%;
      height: 30px;
      position: relative;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom: 1px solid #FFDAA8;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
      .Etor-01 {
        width: 60px;
        height: 30px;
        float: left;
        justify-content: flex-start;
        background: url("../../assets/images/skPad.png") no-repeat 15px 2px;
        background-size:26px 26px;
      }
      .Etor-02 {
        width: 40px;
        height: 30px;
        justify-content: flex-end;
        float: right;
        /*background: url("../../assets/images/Case-11.png") no-repeat 15px 7px;*/
        /*background-size:16px 16px;*/
      }

    }
    .EtorToolbarboxB {
      height: 300px;



    }
  }





















  .caseLayout {
    width: 100%;
    height: 1480px;
    padding: 0px 0px 0px 0px;
    margin-bottom: 20px;
    box-sizing: border-box;

    .details-A {
      width: 100%;
      margin: auto;
      display: flex;
      .area-lf {
        width: 75%;
        float: left;
        position: relative;



        .col_l_main {
          width: 100%;
          .mod-1 {
            width: 100%;
            padding: 10px 0px;
            background-color: #D5F3FF;
            border-bottom: 5px solid #6ED7FF;
            .Hd {
              width: 98%;
              margin:auto;
              padding: 10px 0px;

              box-sizing: border-box;
              border-bottom: 1px solid #9EE3FF;
              display: flex;

              .lf {
                width: 30%;
                font-weight: bold;
                margin-top: 8px;
                justify-content: flex-start;
                img {
                  vertical-align: middle;
                  margin-top: -2px;
                }


              }
              .bc{
                width: 30%;
                justify-content: center;
                display: flex;

                .dtY {
                  height: 28px;
                  padding: 4px 10px;
                  background: rgba(255, 255, 255, 0.9);
                  border: 1px solid #ddd;
                  opacity: 1;
                  border-radius: 30px;
                  position: relative;
                  .Btn-1 {
                    width: 60px
                  }
                  .Btn-2 {
                    width: 70px;
                    height: 32px;
                    padding: 0;
                    vertical-align: middle;
                    margin-top: -4px;
                  }
                  ::v-deep.el-input__inner {
                    height: 28px;
                    line-height: 28px;
                  }
                  ::v-deep.el-select .el-input .el-select__caret {
                    color: #C0C4CC;
                    margin-top: 6px;
                  }
                }
                .dtYPag {
                  height: 36px;
                  line-height: 36px;
                  color: #666;
                  padding: 4px 30px;
                  opacity: 1;
                }
              }

              .rg {
                width: 40%;
                justify-content: flex-end;
                display: flex;
                text-align: right;

                .k {
                  padding-left: 40px;
                  background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 5px;
                  color: #fff;
                  border: 1px solid #06A8C9;
                }
              }
            }
            // 轮播图片区域
            .focus_PiC {
              width: 100%;
              padding: 10px 50px 0 50px;
              box-sizing: border-box;
              margin: auto;
              .scrollList{
                width:100%;
                display: flex;
                .Pic-left{
                  width:250px;
                  height: 448px;
                  /*overflow: auto;*/
                  justify-content: flex-start;
                  .scrollbar{
                  }
                  ::v-deep.el-scrollbar {
                    //height: 100%;
                    height: 448px;
                  }
                  ::v-deep.el-scrollbar__wrap {
                    //overflow: hidden;
                    //width: 100%;
                    //height: 120%;
                  }
                  ::v-deep .infinite-list-wrapper {
                    height: 100%;
                    overflow: auto;
                    .el-scrollbar{
                      height: 100%;
                    }
                    .el-scrollbar__bar{
                      &.is-vertical{
                        width:10px;//滚动条宽度
                      }
                    }
                    .el-scrollbar__wrap{
                      overflow-y: auto;
                      overflow-x:hidden;
                    }
                    .el-scrollbar__thumb {
                      ////可设置滚动条颜色
                      background-color: #278dfd;
                    }
                  }
                }
                .Pic-right {
                  width: 90%;
                  ::v-deep.el-carousel__container {
                    height: 436px;
                    text-align: center;
                  }
                  ::v-deep.el-carousel__item {
                    background: url("../../assets/images/pic-bj.png") no-repeat;
                    background-size: cover;
                  }
                  img{

                    height: 436px;
                    margin: auto;
                    justify-content: center;
                  }
                }
              }
            }
          }
          .mod-2 {
            width: 100%;
            height: 770px;
            padding: 10px 0px;
            background-color: #FFF;
            .Hd{
              width: 98%;
              height: 40px;
              border-bottom: 2px solid #eee;
              margin: 10px auto;
              li{
                float: left;
                width: 200px;
                height: 40px;
                line-height:40px;
                padding: 0px 0;
                text-align: center;
                margin-right: 10px;
                font-weight: bold;
                color: #000;
                cursor: pointer;
                border-radius: 6px 6px 0px 0px;
                img {
                  height: 28px;
                  vertical-align: middle;
                  margin-left: 6px;
                }
              }
              .activeA{
                background-color: #D1EEFE;
                border-bottom: 2px solid #37AEFF;
                color: #000;
              }
            }
            .mod-2-content {
              width: 100%;
              margin: 10px auto 0 auto;
              .mod-2-content-itme {
                width: 98%;
                margin: auto;

                .paperTypes{
                  width: 98%;
                  height: 36px;
                  margin: 10px auto;
                  li{
                    float: left;
                    width: 60px;
                    height: 30px;
                    line-height:30px;
                    padding: 0px 0;
                    text-align: center;
                    margin-right: 10px;
                    border: 1px solid #06A8C9;
                    font-weight: bold;
                    color: #000;
                    cursor: pointer;
                    border-radius: 30px;
                    img {
                      vertical-align: middle;
                      margin-left: 6px;
                    }
                  }
                  .activeB{
                    border: 1px solid #06A8C9;
                    background-color: #06A8C9;
                    color: #FFF;
                  }
                }

                /*交互答疑*/
                .answering {
                  width: 100%;
                  margin: auto auto 0px auto;
                  background-color: #fff;

                  .top {
                    width: 98%;
                    height: 34px;
                    line-height: 24px;
                    margin: 10px auto;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: flex-end;
                    .bTn {
                      height: 32px;
                    }
                  }
                  .answeringQuestion {
                    width: 100%;
                    height: 674px;
                    margin: 10px auto 0 auto;
                    display: flex;
                    .answering-lf {
                      width: 30%;
                      height: 650px;
                      overflow-y: auto;
                      .prolist {
                        box-sizing: border-box;
                        padding: 10px 0 0 0;
                        float: left;
                        width: 100%;
                        padding-bottom: 6px;
                        box-sizing: border-box;
                        border-right: 1px solid #ddd;
                        border-bottom: 1px solid #ddd;
                        list-style: none;
                        overflow: hidden;
                        text-align: left;
                        .ansBtnA {
                          height: 24px;
                          padding: 0px 10px;
                        }
                        .pl01 {
                          width: 96%;
                          line-height: 20px;
                          margin: auto;
                          display: flex;
                          .pA {
                            width: 70%;
                            line-height: 24px;
                          }
                          .pB {
                            width: 30%;
                            text-align: right;
                            .ansBtn0 {
                              border: 1px solid #299B4C; background-color: #299B4C; color: #fff
                            }
                          }
                        }
                        .pl02 {
                          width: 98%;
                          line-height: 36px;
                          margin: auto;
                          display: flex;
                          .pA {
                            justify-content: flex-start;
                            color: #999;
                          }
                          .pB {
                            justify-content: center;
                            color: #999;
                          }
                          .pC {
                            justify-content: flex-end;
                            color: #1A86FF;
                            text-align: right;
                          }
                        }
                      }
                      .activeAnswering {
                        color: #0090DA;
                        border-left: 4px solid #1A86FF;
                        border-right: 1px solid #E8F7FE;
                        background: #E8F7FE;
                        opacity: 1;
                        .pl01 {
                          width: 96%;
                          line-height: 20px;
                          margin: auto;
                          display: flex;
                          .pA {
                            width: 70%;
                            color: #0090DA;
                            font-weight: bold;
                          }
                          .pB {
                            width: 30%;
                            text-align: right;
                          }
                        }
                      }
                    }
                    .answering-rg {
                      width: 70%;
                      background-color: #E8F7FE;
                      /* 聊天列表 */
                      .lt-twwt {
                        padding: 0px 10px;
                        box-sizing: border-box;
                        border-radius: 6px;
                        border: 1px solid #FF5A00;
                        margin: 20px auto;
                        overflow-y: auto;
                        position: relative;
                        .Tip {
                          position: absolute;
                          width: 110px;
                          height: 24px;
                          line-height: 24px;
                          text-align: center;
                          color: #FF5A00;
                          font-weight: bold;
                          right: 0;
                          background: #FFE8DC;
                          opacity: 1;
                          border-radius: 0px 10px 0px 10px;
                        }
                        .TntToplist {
                          width: 98%;
                          margin: auto;
                          padding: 10px 10px;
                          box-sizing: border-box;
                          display: flex;
                          border-bottom: 1px solid #AFE6FF;
                          .portrait {
                            width: 6%;
                            margin-top: 10px;
                            display: inline-block;
                            text-align: center;
                            img {
                              width: 40px;
                              height: 40px;
                              border-radius: 50px;
                              margin: auto;
                            }
                            p {
                              width: 100%;
                              color: #999999;
                              text-align: center;
                              white-space:nowrap;
                              overflow:hidden;
                              text-overflow:ellipsis;
                            }
                          }
                          .intR-content {
                            width: 92%;
                            padding-left: 20px;
                            justify-content: flex-end;
                            padding-top: 20px;
                            box-sizing: border-box;
                            .tR01 {
                              width: 100%;
                              height: 26px;
                              font-weight: bold;
                              display: flex;
                              .lT-A1 {
                                width: 650px;
                                justify-content: flex-start;
                                color: #1A86FF;
                                white-space:nowrap;
                                overflow:hidden;
                                text-overflow:ellipsis;
                              }
                              .lT-A2 {
                                width: 200px;
                                text-align: right;
                                justify-content: flex-end;
                                color: #999;
                              }
                            }
                            .tR02 {
                              width: 100%;
                              max-height: 200px;
                              overflow-y: auto;
                              .lT-content {
                                width: 100%;
                                justify-content: flex-start;
                                color: #333;
                                ::v-deep p {
                                  word-wrap: break-word;
                                  white-space: normal;
                                  word-break: break-all;
                                  img {
                                    border: 1px solid #eee;
                                    height: 160px;
                                    display: block;
                                  }
                                }
                              }

                            }
                          }
                        }
                      }
                      /* 聊天列表 */
                      .lt-inf {
                        padding: 0 10px;
                        box-sizing: border-box;
                        height: 560px;
                        margin: auto;
                        overflow-y: auto;
                        .lt-list {

                        }
                        .ansBtnB {
                          height: 24px;
                          padding: 0px 10px;
                        }
                      }
                      /* 聊天回复 */
                    }
                  }
                  .focus_main6 {
                    width: 98%;
                    height: 730px;
                    margin: 10px auto;
                    border: solid 1px #ccc;
                    position: relative;
                    .tab {
                      width: 100%;
                      position: absolute;
                      display: flex;
                      // zuob
                      .problem {
                        width: 30%;
                        overflow: hidden;
                        padding: 0;
                        margin: 0;
                        float: left;
                        .prolist {
                          box-sizing: border-box;
                          padding: 10px 0 0 0;
                          float: left;
                          width: 100%;
                          padding-bottom: 6px;
                          padding-left: 10px;
                          box-sizing: border-box;
                          list-style: none;
                          text-align: left;
                          .pl01 {
                            width: 96%;
                            line-height: 20px;
                            margin: auto;
                            display: flex;
                            .pA {
                              width: 70%;
                            }
                            .pB {
                              width: 30%;
                              text-align: right;
                            }
                          }
                          .pl02 {
                            width: 96%;
                            height: 36px;
                            line-height: 36px;
                            margin: auto;
                            display: flex;
                            .pA {
                              width: 33%;
                              justify-content: flex-start;
                              color: #999;
                            }
                            .pB {
                              width: 40%;
                              justify-content: center;
                              color: #999;
                            }
                            .pC {
                              width: 26%;
                              justify-content: flex-end;
                              color: #1A86FF;
                              text-align: right;
                            }
                          }
                        }
                        .activef {
                          border-left: 3px solid #1A86FF;
                          background-color: #E8F7FE;
                          color:#1A86FF ;
                        }
                      }
                      .prolistHd {
                        width: 1300px;
                        height: 729px;
                        display: none;
                        background-color: #E8F7FE;
                        border-top: 0px;


                      }
                      .current {
                        display: block;
                        /* 聊天列表 */
                        .lt-inf {
                          padding: 0 10px;
                          box-sizing: border-box;
                          height: 620px;
                          margin: auto;
                          overflow-y: scroll;
                        }

                      }

                    }

                  }
                }
                /*一课一练*/
                .exercisesModular {
                  width: 100%;
                  margin: auto auto 0px auto;
                  background-color: #fff;
                  .top {
                    width: 98%;
                    height: 34px;
                    line-height: 34px;
                    margin: 10px auto;
                    box-sizing: border-box;
                    display: flex;
                    .lf{
                      width: 50%;
                      justify-content: flex-start;
                    }
                    .rg{
                      width: 50%;
                      text-align: right;
                      justify-content: flex-end;
                    }
                  }
                  // 试题与答题卡
                  .topicOr-card {
                    display: flex;
                    .kong {
                      width: 400px;
                      margin: auto;
                      text-align: center;
                      img {
                        margin: auto;
                        height: 200px;
                      }
                      p {
                        height: 30px;
                        line-height: 30px;
                        color: #c6c6c6;
                        span {
                          color: #238bff;
                          cursor:pointer
                        }
                      }
                    }
                    .card-cod {
                      width: 100%;

                      .tCart {
                        width: 100%;
                        display: flex;
                        .st {
                          width: 50%;
                          height: 650px;
                          justify-content: flex-start;
                          overflow-y: auto;
                        }
                        .dt {
                          width: 50%;
                          height: 650px;
                          justify-content: flex-end;
                          overflow-y: auto;
                        }
                      }

                    }


                  }
                }
              }
            }
          }


          .blackName2 {
            width: 100%;
            height: 34px;
            line-height: 24px;
            margin-bottom: 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #f1f1f1;
            display: flex;

            .lf {
              width: 50%;

              img {
                vertical-align: middle;
                margin-top: -2px;
              }
            }

            .rg {
              width: 50%;
              display: flex;
              justify-content: flex-end;
              text-align: right;

              .k {
                padding-left: 30px;
                background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 5px;
                color: #fff;
                border: 1px solid #06A8C9;

              }
            }
          }
          // 试题与答题卡
          .focus_main2 {
            height: 800px;
            border: solid 1px #ccc;
            display: flex;
            .kong {
              width: 400px;
              margin: auto;
              text-align: center;
              img {
                margin: auto;
                height: 200px;
              }
              p {
                height: 30px;
                line-height: 30px;
                color: #c6c6c6;
                span {
                  color: #238bff;
                  cursor:pointer
                }
              }
            }
            .st {
              width: 50%;
              height: 790px;
              overflow-y: auto;
            }
            .dt {
              width: 50%;
              height: 790px;
              overflow-y: auto;
            }

          }
        }

        /*操作按钮*/
        .Btn {
          width: 14px;
          height: 60px;
          margin-top: -480px;
          position: absolute;
          margin-left: 100%;
          opacity: 1;

          .BtnA {
            width: 14px;
            height: 60px;
            background: url("../../assets/images/arrow-right.png") no-repeat;
            background-size: cover;
            color: #007B94;
            cursor: pointer;
            padding-top: 20px;
            padding-right: 6px;
            position: absolute;
            margin-left: -14px;
            box-sizing: border-box;
            border-radius: 5px 0px 0px 5px;

            z-index: 99;
          }

          .BtnB {
            width: 14px;
            height: 60px;
            background: url("../../assets/images/arrow-left.png") no-repeat;
            background-size: cover;
            color: #007B94;
            cursor: pointer;
            padding-top: 20px;
            padding-right: 10px;
            box-sizing: border-box;
            border-radius: 0px;
            z-index: 99;
          }
        }
      }
      .area-rg {
        width: 25%;
        background-color: #FFF7CF;
        border-left: 5px solid #FFE568;
        //background: #E7F1F3;
        box-sizing: border-box;
        float: right;

        .blackName {
          width: 100%;
          height: 30px;
          line-height: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #E5E5E5;

          img {
            height: 28px;
            vertical-align: middle;
            margin-top: -2px;
          }
        }

        .bick-r-01 {
          width: 100%;
          height: 74px;
          padding: 5px;
          box-sizing: border-box;
          margin-bottom: 10px;
          cursor:pointer;
          img {
            width: 100%;
            height: 74px;
          }
        }

        .bick-r-02 {
          width: 100%;
          height: 1254px;
          padding: 10px;
          box-sizing: border-box;

          .note {
            width: 100%;
            //height: 1150px;
            overflow-y: auto;
            padding-bottom: 0px;
            box-sizing: border-box;
            margin: auto;
            .notecase {
              width: 100%;
              margin-bottom: 20px;
              .cName {
                width: 100%;
                background: linear-gradient(90deg, #FF9100 0%, rgba(255, 145, 0, 0) 100%);
                height: 24px;
                line-height: 24px;
                display: flex;
                .Aam1 {
                  width: 88%;
                  height: 24px;
                  box-sizing: border-box;
                  padding-left: 20px;
                  color: #fff;
                }
                .Aam2 {
                  width: 30px;
                  color: #2c3e50;
                  i {
                    width: 30px;
                    margin-left: 20px;
                    color: #ffcd78;
                  }


                }
              }
              .cList {
                width: 100%;
                .list {
                  width: 100%;
                  display: flex;
                  position: relative;
                  .LA {
                    width: 10px;
                    height: 24px;
                    box-sizing: border-box;

                    padding-top: 18px;
                    .yd {
                      width: 3px;
                      height: 3px;
                      background-color: #238bff;
                      border-radius: 50px;
                    }
                  }
                  .LB {
                    width: 100%;
                    padding-top: 6px;
                    border-bottom: 0px solid #E5E5E5;
                    .Ob{
                      line-height: 24px;
                      height: 70px;
                      overflow-y: auto;
                    }
                    ::v-deep.el-input__inner {
                      width: 100%;
                      background-color: #FBF8D9;
                      height: auto;
                      border-radius: 0px;
                      border-top-width: 0px;
                      border-left-width: 0px;
                      border-right-width: 0px;
                      border-bottom-width: 0px;
                      /*outline: medium;*/
                    }
                    ::v-deep.el-textarea__inner {
                      display: block;
                      resize: vertical;
                      padding: 0px 15px;
                      line-height: 20px;

                      box-sizing: border-box;
                      width: 100%;
                      color: #606266;
                      background-color: #FBF8D9;
                      background-image: none;
                      border-top-width: 0px;
                      border-left-width: 0px;
                      border-right-width: 0px;
                      border-bottom: 0px solid #E5E5E5;
                      border-radius: 0px;
                      transition: none;
                    }
                    #textarea {
                      width: 280px;
                      background-color: #FBF8D9;
                      height: auto;
                      line-height: 24px;
                      border-radius: 0px;
                      border-top-width: 0px;
                      border-left-width: 0px;
                      border-right-width: 0px;
                      border-bottom-width: 1px;
                      /*outline: medium;*/
                    }

                  }
                }
              }
            }
          }

          .noteBTn {
            width: 100%;
            height: 38px;
            padding: 5px 0px 12px 10px;
            box-sizing: border-box;
            text-align: center;
            margin: auto;
            display: flex;
            justify-content: center;
            .bTn {
              height: 36px;
            }
          }
        }

      }
    }





    .case-conent-detal {
      width: 98%;
      margin: 20px auto;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 0.9;










      /* 聊天发布 */
      .lt-fb {
        padding: 0 10px;
        box-sizing: border-box;
        height: 100px;
        margin: auto;
        display: flex;
        .pA {
          width: 90%;
          justify-content: flex-start;
        }
        .pB {
          width: 10%;
          text-align: right;
          justify-content: flex-end;
        }
      }



    }
  }
  .addclass {
    color: red;
  }
  .CaseMenu {
    line-height: 40px;
    cursor:pointer;
    border-left: 1px solid #84c8ed;
    border-right: 1px solid #84c8ed;
    text-align: center;
    color: #fff;
  }
  .active {
    color: #fff;
    background: #0055C5 ;
    opacity: 1;
  }
  .edu-container {
    margin: auto;
  }







  .intlist {
    width: 98%;
    margin: auto;
    padding: 10px 10px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #AFE6FF;
    .portrait {
      width: 6%;
      justify-content: flex-start;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }
    }
    .intR {
      width: 94%;
      justify-content: flex-end;
      .tR01 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        .lf {
          width: 50%;
          color: #999999;
          text-align: left;
          justify-content: flex-start;
        }
        .rg {
          width: 50%;
          color: #999999;
          text-align: right;
          justify-content: flex-end;
        }
      }
      .tR02 {
        width: 100%;
        word-wrap: break-word;
        white-space: normal;
        word-break: break-all;
        ::v-deep p {
          word-wrap: break-word;
          white-space: normal;
          word-break: break-all;
          img {
            border: 1px solid #eee;
            height: 160px;
            display: block;
          }
        }
      }
      .tR03 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div {
          vertical-align: middle;
          cursor: pointer;
          img {
            margin-right: 6px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .Lintlist {
    width: 94%;
    margin: auto 1% auto auto;
    padding: 10px 0;
    display: flex;
    float: right;
    border-bottom: 1px solid #AFE6FF;
    .portrait {
      width: 6%;
      justify-content: flex-start;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }
    }
    .intR {
      width: 94%;
      justify-content: flex-end;
      .tR01 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        .lf {
          width: 50%;
          color: #999999;
          text-align: left;
          justify-content: flex-start;
        }
        .rg {
          width: 50%;
          color: #999999;
          text-align: right;
          justify-content: flex-end;
        }
      }
      .tR02 {
        width: 100%;
      }
      .tR03 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div {
          vertical-align: middle;
          img {
            margin-right: 6px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .AnswerSheet {
    width: 96%;
    overflow-y: auto;
    margin: auto;
    overflow-x: hidden;

    .Acontent {
      width: 100%;
      padding: 0px 20px 0px 0px;
      .Aname {
        line-height: 40px;
        font-weight: bold;
      }
      .Acn {
        padding:  0 6px 10px 0px;
      }
      .Alist {
        width: 100%;
        line-height: 30px;
        position: relative;
        display: flex;
        .A1 {
          width: 20px;
          float: left;
        }
        .A2 {
          width: 100%;
          float: left;
          padding-left: 20px;
          box-sizing: border-box;
          /*选项*/
          .Aoption {
            width: 100%;
            display: flex;
            .A-tem {
              width: 60px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border: 1px solid #707070;
              margin: 0 10px;
            }
          }
          /*非选项*/
          .Foption {
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
      // 批改结果
      .Alist-Results {
        width: 100%;
        line-height: 30px;
        position: relative;
        display: flex;
        .A1 {
          width: 20px;
          float: left;
        }
        .A2 {
          width: 100%;
          float: left;
          padding-left: 20px;
          box-sizing: border-box;
          /*选项*/
          .Aoption {
            width: 100%;
            display: flex;
            .A-tem {
              width: 60px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border: 1px solid #707070;
              margin: 0 10px;
            }
          }
          /*非选项*/
          .Foption {
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
      .Alist-D {
        width: 100%;
        margin: 10px 0px;
        padding: 5px 0px;
        box-sizing: border-box;
        position: relative;
        .A1 {
          width: 30px;
          height: 24px;
          line-height: 24px;
          text-align: right;
          box-sizing: border-box;
          padding-right: 10px;
          float: left;
          position: absolute;
        }
        .A2 {
          width: 94%;
          padding-left: 40px;
          box-sizing: border-box;
          display: flex;
          float: left;
          /*选项*/
          .Aoption {
            width: 620px;
            padding-right: 20px;
            display: flex;
            .A-tem {
              width: 40px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              border: 1px solid #707070;
              margin: 0 10px;
            }

          }
          /*是否正确*/
          .correct-Box {
            width: 30%;
            img {
              margin-top: 5px;
              margin-right: 20px;
            }
          }
          /*非选项*/
          .Foption {
            width: 100%;
            .A-tem {
              width: 100%;
              height: 30px;
              border-bottom: 1px solid #303133;
              margin: 0 10px;
            }
          }
        }
        .A3 {
          width: 500px;
          float: left;
          .D1 {
            width: 100%;
            box-sizing: border-box;
            padding-left: 40px;
            display: flex;
            .D1-A1 {
              width: 65px;
              background-color: #FA6E86;
              height: 24px;
              line-height: 24px;
            }
            .D1-A2 {
              line-height: 24px;
              width: 390px;
            }
          }
        }

      }
      .Blist-E {
        width: 100%;
        margin: 10px 0px;
        padding: 5px 0px;
        box-sizing: border-box;
        position: relative;
        .A1 {
          width: 30px;
          height: 24px;
          line-height: 24px;
          text-align: right;
          box-sizing: border-box;
          padding-right: 10px;
          float: left;
          position: absolute;
        }
        .A2 {
          width: 95%;
          padding-left: 40px;
          box-sizing: border-box;
          display: flex;
          float: left;
          .Da-1 {
            width: 620px;
            margin-top: 0px;
            margin-right: 20px;
            box-sizing: border-box;
            background-color: #FBF8D9;
            line-height: 24px;
            border: 1px solid #e5e9f2;
          }
          .Da-2 {
            width: 620px;
            margin-top: 0px;
            padding-right: 20px;
            box-sizing: border-box;
            line-height: 24px;
            ::v-deep.AoptionB {
              width: 100%;
              display: flex;
              p {
                img {
                  width: 100%;
                  border: 1px solid #E5E5E5;
                }
              }
            }
          }
          /*选项*/

          /*是否正确*/
          .correct-Box {
            width: 30%;
            img {
              margin-top: 5px;
              margin-right: 20px;
            }
          }
          /*非选项*/
          .Foption {
            width: 100%;
            .A-tem {
              width: 100%;
              height: 30px;
              border-bottom: 1px solid #303133;
              margin: 0 10px;
            }
          }

        }
        .A3 {
          width: 750px;
          float: left;
          .D1 {
            width: 100%;
            box-sizing: border-box;
            padding-left: 50px;
            display: flex;
            .D1-A1 {
              height: 30px;
              line-height: 30px;
            }
            .D1-A2 {
              line-height: 30px;
              width: 390px;
            }
          }
        }

      }
    }

  }
  .active{
    border:3px solid #FF9100;
  }

  //.pic-item{
  //  width:230px;
  //  height: 98px;
  //  background-color: red;
  //  margin-bottom: 10px;
  //}
  .pic-item{
    width:230px;
    height: 104px;
    margin-bottom: 6px;
    position:relative;
    z-index:1;

  }
  .pic-item img{
    height: 104px;
    position:absolute;
    margin: 0px auto;
    left:0;
    right:0;
    width:100%;
    z-index: -1;
    *zoom:1;
  }
  .pic-item:before {
    content: "";
    display: inline-block;
    padding-bottom: 100%;
    width: 0.1px; /*必须要有数值，否则无法把高度撑起来*/
    vertical-align: middle;
  }

  .el-carousel__item h3 {
    color: #475669;
    opacity: 1;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #999;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #999;
  }
  .Loading-backg {
    height: 100%;
    background-color: #fff;
    .kong-loshd {
      width: 400px;
      margin: auto;
      padding-top: 8%;
      box-sizing: border-box;
      text-align: center;
      img {
        margin: auto;
        height: 200px;
      }
      p {
        height: 30px;
        line-height: 30px;
        color: #c6c6c6;
        span {
          color: #238bff;
          cursor:pointer
        }
      }
    }
  }
</style>
